import "./Components.css"

function Footer(){
    return(
        <div className="footer">
            <p className="textoFooter">Sitio Desarrollado por Matias Taberner</p>
        </div>
    )
}

export default Footer;