import Productos from "../Components/Productos";


function Home(){
    return(
        <div>
          <Productos />
        </div>
    )
}


export default Home;